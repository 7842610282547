import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export const League = () => {
  return (
    <header id="league" className="p-4 my-2 rounded-5 leagueImg">
      <div className="container">
        <h1 className="display-5 fw-bold">Leagues</h1>
        
        <div className="row mb-4">
          <div className="col-md-6">
            <h2>APA</h2>
            <h3>Ready to Rack 'Em Up?</h3>
            <div>
              Joining is easy! Visit
              <a
                href="https://poolplayers.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}poolplayers.com
              </a>
              {" "}to find your local league and sign up today. Don't miss this
              chance to be part of something bigger, to compete, grow, and
              enjoy the game we all love. Whether you're aiming to be the next
              champion or just looking for a fun night out, the APA Pool
              League welcomes you.
            </div>
            <div>
              Want to play out of PJ's? Contact management for availability
              via email at <a href="mailto:pjspub1717@gmail.com">pjspub1717@gmail.com</a>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img 
              src="./billiards1.jpg" 
              className="img-fluid" 
              alt="Billiards" 
            />
          </div>
        </div>

        <hr />

        <div className="text-end">
          <h2>Darts</h2>
          <h3>Aim High, Throw True: Join Dart League Today!</h3>
          <div className="row mt-4">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <img 
                src="./darts.jpg" 
                className="img-fluid" 
                alt="Darts" 
              />
            </div>
            <div className="col-md-6">
              <div>
                For those interested in joining a dart league, the Inland
                Empire Dart League offers an opportunity to engage with fellow
                dart enthusiasts. This league is affiliated with the National
                Dart Association, ensuring a structured and competitive
                environment for players. This league promises a
                community-focused and enjoyable experience for dart players of
                various skill levels. For detailed information, please visit
                their{" "}
                <a
                  href="https://www.facebook.com/groups/256776904432712?locale=tr_TR"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook page
                </a>.
              </div>
              <div>
                Want to play out of PJ's? Contact management for availability
                via email at <a href="mailto:pjspub1717@gmail.com">pjspub1717@gmail.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
