import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export const Poker = () => {
  return (
    <header id="poker" className="p-4 rounded-5 pokerBackgroundImg">
      <div className="container text-end">
        <h1 className="display-5 fw-bold">
          Poker{" "}
          <img
            src="./poker2.png"
            width="128"
            height="128"
            alt="Poker Icon"
          />
        </h1>

        <div className="row">
          <div className="col-md-6">
            <img
              src="./poker1.jpg"
              className="img-fluid"
              alt="Poker Game"
            />
          </div>

          <div className="col-md-6 d-flex flex-column justify-content-center">
            <p>
              🍻 Enjoy the thrill of the game while sipping on your favorite
              drinks from our well-stocked bar. Our friendly staff is here to
              ensure you have everything you need for an unforgettable poker
              experience.
            </p>

            <div className="container-fluid text-center">
              <h2>Monthly Poker Tournament On October 27th @ 1PM</h2>
              <div>
                <div className="fw-bold">Sign-Ups:</div>
                <p>The bar will have sign-up sheets available.</p>
                <p>
                  Sign up before noon on the day of the event to get an extra
                  3k in chips.
                </p>

                <a
                  href="https://www.facebook.com/groups/204943466202129"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Spokane Poker's Facebook page
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-4">
          <hr />

          {/* Poker Schedule Button */}
          <button
            className="btn custom-btn mb-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#pokerSchedule"
            aria-expanded="false"
            aria-controls="pokerSchedule"
          >
            Poker Schedule
          </button>

          {/* Collapsible poker schedule */}
          <div className="collapse" id="pokerSchedule">
            <div>
              <div className="fw-bold m-1">Tuesday & Saturday:</div>
              <p>@ 7:15 PM</p>
              <p>$20 buy-in, $10 add-on, 3k on-time bonus.</p>
            </div>
            <div>
              <div className="fw-bold m-1">Sunday:</div>
              <p>@ 7:00 PM</p>
              <p>$25 buy-in, $5 bounty, 1 optional add-on per entry.</p>
              <p>2-10 live game to follow - NO RAKE.</p>
            </div>
          </div>

          <div className="fw-bold m-2">THERE IS NO HOUSE FEE</div>
          <div className="fw-bold m-1">ALL MONEY IS PAID OUT</div>
        </div>
      </div>
    </header>
  );
};
